import { Transport } from "../../../data/api/types/driver";
import { useGetDriversScheduleHistoryDayQuery } from "../../../data/api/hooks";
import { useMemo } from "react";
import moment from "moment";
import { ScheduleDay } from "../../../data/api/types/schedule";

export type DayTaskStates = {
  abbrev: string;
  available: boolean;
  description: string;
  shift_category: number | null;
  state_id: number;
};

export interface DriverDayState {
  autocolumn: number;
  day_in_month: string;
  state: number | null;
  state_name: string | null;
  state_short_name: string | null;
  expired_docs: boolean | null;
  work_depot_id: number | null;
}

export interface DriverDayStates {
  sl_number: number;

  home_depot_id: number | null;
  driver_days: DriverDayState[];
}

export interface ScheduleReport {
  daytask_states: DayTaskStates[];
  drivers_schedule: DriverDayStates[];
}

export interface ScheduleReportData {
  data: ScheduleReport;
}

export function useScheduleRecapitulation(
  selectedTransport: Transport | "",
  selectedDepot: number,
  selectedDate: string,
  selectedAvtoColumn: number,
  day_roster:
    | Record<
        number,
        {
          driver_id: number;
          scheduleDay: ScheduleDay;
        }
      >[]
    | undefined,
  day_workshift_ids: string[] | undefined
) {
  const getDriversScheduleQueryData = useMemo(
    () => ({
      transport: selectedTransport,
      garage: selectedDepot,
      avtocolona: selectedAvtoColumn,
      date: selectedDate,
      sl_number: null,
    }),
    [selectedAvtoColumn, selectedDate, selectedDepot, selectedTransport]
  );

  const { data, isLoading, isFetching, isError } =
    useGetDriversScheduleHistoryDayQuery(getDriversScheduleQueryData, {
      refetchOnMountOrArgChange: true,
      skip: !(selectedDepot !== -1 && selectedTransport !== ""),
    });
  // console.log("With history", data);

  const rows = useMemo(() => {
    const state_drivers = data?.data.drivers_schedule
      ?.filter(
        (d) =>
          d.driver_days.filter(
            (d) =>
              d.day_in_month === selectedDate &&
              d.autocolumn === selectedAvtoColumn
          ).length > 0
      )
      ?.map((d) => {
        const driver_day = d.driver_days.find(
          (a) =>
            a.day_in_month === selectedDate &&
            a.autocolumn === selectedAvtoColumn
        );
        const driver_state = driver_day?.history_days
          .slice()
          .sort((a, b) =>
            moment(a.updated_at) < moment(b.updated_at) ? 1 : -1
          )
          ?.find((d) => moment(d.updated_at) < moment(selectedDate));

        if (
          d.driver_days.find(
            (a) =>
              a.day_in_month === selectedDate &&
              a.autocolumn === selectedAvtoColumn
          )?.state !== driver_state?.state
        )
          console.log(
            "Различно състояние:",
            d.sl_number,
            driver_state?.state,
            d.driver_days.find(
              (a) =>
                a.day_in_month === selectedDate &&
                a.autocolumn === selectedAvtoColumn
            )?.state
          );

        const drv = d.driver_days.find((a) => a.day_in_month === selectedDate);

        return {
          sl_number: d.sl_number,
          //state: d.driver_days.find((a) => a.day_in_month === selectedDate)?. state,
          state:
            moment(selectedDate) > moment()
              ? d.driver_days.find(
                  (a) =>
                    a.day_in_month === selectedDate &&
                    a.autocolumn === selectedAvtoColumn
                )?.state
              : driver_state?.state,
          expired_docs: driver_day?.expired_docs,
          comandirovan: drv?.work_depot_id !== selectedDepot,
          comandirovan_in:
            drv?.work_depot_id !== d.home_depot_id &&
            drv?.work_depot_id === selectedDepot,
          category_id: d.category.id,
        };
      })
      .reduce<{ [key: string]: number[] }>((acc, x) => {
        let state = x.state
          ? //x.state !== 15 &&
            x.state !== 27 && x.state !== 5
            ? x.state
            : 24
          : 1;

        if (state === 28) state = 21; //ако е 1-ва смяна задължително да излиза само 1ва смяна и да са групирани
        if (state === 29) state = 22; //ако е 2-ра смяна задължително да излиза само 1ва смяна и да са групирани
        if (state === 26) state = 10; //ако е НО   да са групирани
        if (state === 25 || state === 33) state = 6; //ако е О  да са групирани
        if (state === 34) state = 7; //ако е Б?   да са групирани

        if (state === 1 && x.expired_docs) state = 35;

        if (state === 1 && x.category_id && [2, 4, 9].includes(x.category_id))
          state = 39;

        if (x.comandirovan) state = 12;
        if (x.comandirovan_in) state = 38;

        if (state !== 13) {
          if (acc[state] === undefined) {
            acc[state] = [x.sl_number];
          } else {
            acc[state].push(x.sl_number);
          }
        }

        //проверка дали ако е в състояние че е на работа има назначена задача по наряд
        if (
          data.data.daytask_states
            .filter((s) => s.available && s.state_id > 1)
            .map((s) => s.state_id)
            .includes(state)
        ) {
          const drv_day_rec = day_roster?.find(
            (d) => Object.values(d)[0].driver_id === x.sl_number
          );
          if (drv_day_rec) {
            const drv_day = Object.values(drv_day_rec)[0];
            if (
              drv_day.scheduleDay.state !== 37 &&
              drv_day.scheduleDay.daytasks.filter((d) =>
                d.shift_id ? day_workshift_ids?.includes(d.shift_id) : false
              ).length <= 0
            ) {
              //добавям състояние -1 ако водача няма задача в наряда
              if (acc[-1] === undefined) {
                acc[-1] = [x.sl_number];
              } else {
                acc[-1].push(x.sl_number);
              }
            }
          }
        }

        return acc;
      }, {});

    return state_drivers;
    //,{};
  }, [
    data?.data.daytask_states,
    data?.data.drivers_schedule,
    day_roster,
    day_workshift_ids,
    selectedAvtoColumn,
    selectedDate,
    selectedDepot,
  ]);

  const totalDriverCount = useMemo(() => {
    const state_drivers = data?.data.drivers_schedule
      ?.filter(
        (d) =>
          d.driver_days.filter(
            (d) =>
              d.day_in_month === selectedDate &&
              d.autocolumn === selectedAvtoColumn
          ).length > 0
      )

      ?.map((d) => ({
        sl_number: d.sl_number,
        state: d.driver_days.find((a) => a.day_in_month === selectedDate)
          ?.state,
      }))
      .reduce((acc, x) => {
        x.state !== 13 ? (acc += 1) : acc;
        return acc;
      }, 0);
    if (state_drivers) return state_drivers;
    else return 0;
  }, [data?.data.drivers_schedule, selectedAvtoColumn, selectedDate]);
  return {
    data,
    isLoading: isLoading || isFetching,
    isError,
    rows,
    totalDriverCount,
  };
}
